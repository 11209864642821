<template>
    <div v-if="showDiv" class="columns is-centered broad">
        <form @submit.prevent="fetch">
            <div class="box p-3 broad-noti">
                <div class="title has-text-centered has-text-dark is-5">
                    {{ i18n('Completar la información') }}
                    <div class="container mt-2">
                        <div class="notification is-info is-light is-small mt-1">
                            <p class="text-tam" v-if="notiPassword">
                                La contraseña temporal debe actualizarse, ya que es
                                ficticia y no debe utilizarse con fines reales.
                                Por favor, cambia la contraseña a una válida y precisa. Gracias.
                            </p>
                            <p class="text-tam" v-else>
                                Si prefieres no ingresar con una red social, puedes
                                acceder a tu cuenta utilizando tu dirección de correo
                                electrónico y agregando una contraseña. ¡Gracias!
                            </p>
                        </div>
                        <div class="notification  is-size-7">
                            <h4
                                class="subtitle is-6 has-text-grey-darker requirements">
                                {{ i18n('Password Requirements') }}
                            </h4>
                            <p>
                                - {{ i18n('Minimum one digit, upper and lower case letters') }}
                            </p>
                            <p>
                                - {{ i18n('Minimum 8 characters and maximum 15') }}
                            </p>
                            <p>
                                - {{ i18n('A special character') }} /*-+)!$&
                            </p>
                        </div>
                    </div>
                </div>
                <div class="bloq">
                    <label for="password">{{ i18n("Password") }}:</label>
                    <input class="input is-info"
                        name="password"
                        type="password"
                        id="password"
                        v-model="form.password"
                        required>
                    <p class="has-text-danger is-size-7 mt-1"
                        v-if="errors.has('password')">
                        {{ errors.get('password') }}
                    </p>
                </div>
                <div class="bloq my-2">
                    <label for="password_confirmation">
                        {{ i18n("Repeat Password") }}:
                    </label>
                    <input class="input is-info "
                            name="password_confirmation"
                            type="password" id="password_confirmation"
                            v-model="form.password_confirmation" required>
                </div>
                <div class="bloq">
                    <button class="button is-primary is-fullwidth" type="submit">
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div v-else class="columns is-centered broad">
        <form @submit.prevent="fetch">
            <div class="box p-3 broad-noti">
                <div class="title has-text-centered has-text-dark is-5">
                    {{ i18n('Completar la información') }}
                    <div class="container mt-2">
                        <div class="notification is-info is-light is-small ">
                            <p class="text-tam">
                                Por favor, ten en cuenta que los nombres y correos electrónicos
                                que aparecen aquí son información temporal y deben ser
                                actualizados con la información correcta. No utilices estos
                                datos para ningún propósito real, ya que son meramente
                                ficticios y temporales. Asegúrate de cambiar el nombre y
                                el correo electrónico por la información válida y precisa
                                correspondiente. Gracias por tu comprensión y colaboración.
                            </p>
                        </div>
                        <div class="notification is-info is-light is-small mt-1">
                            <p class="text-tam">
                                Si prefieres no ingresar con una red social, puedes
                                acceder a tu cuenta utilizando tu dirección de correo
                                electrónico y agregando una contraseña. ¡Gracias!
                            </p>
                        </div>
                        <div class="notification  is-size-7">
                            <h4
                                class="subtitle is-6 has-text-grey-darker requirements">
                                {{ i18n('Password Requirements') }}
                            </h4>
                            <p>
                                - {{ i18n('Minimum one digit, upper and lower case letters') }}
                            </p>
                            <p>
                                - {{ i18n('Minimum 8 characters and maximum 15') }}
                            </p>
                            <p>
                                - {{ i18n('A special character') }} /*-+)!$&
                            </p>
                        </div>
                    </div>
                </div>
                <div class="bloq">
                    <label for="name">Nombre:</label>
                    <input class="input is-info"
                        type="text" id="name"
                        v-model="form.name"
                        required>
                    <p class="has-text-danger is-size-7 mt-1"
                        v-if="errors.has('name')">
                        {{ errors.get('name') }}
                    </p>
                </div>
                <div class="bloq">
                    <label for="email">{{ i18n("Email") }}:</label>
                    <input class="input is-info"
                        type="email"
                        id="email"
                        v-model="form.email"
                        required>
                    <p class="has-text-danger is-size-7 mt-1"
                        v-if="errors.has('email')">
                        {{ errors.get('email') }}
                    </p>
                </div>
                <div class="bloq">
                    <label for="password">{{ i18n("Password") }}:</label>
                    <input class="input is-info"
                        name="password"
                        type="password"
                        id="password"
                        v-model="form.password"
                        required>
                    <p class="has-text-danger is-size-7 mt-1"
                        v-if="errors.has('password')">
                        {{ errors.get('password') }}
                    </p>
                </div>
                <div class="bloq my-2">
                    <label for="password_confirmation">
                        {{ i18n("Repeat Password") }}:
                    </label>
                    <input class="input is-info "
                            name="password_confirmation"
                            type="password" id="password_confirmation"
                            v-model="form.password_confirmation" required>
                </div>
                <div class="bloq">
                    <button class="button is-primary is-fullwidth" type="submit">
                        Enviar
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Errors from '@enso-ui/laravel-validation';

export default {
    name: 'AddPassword',
    inject: ['errorHandler', 'route', 'i18n', 'toastr', 'http'],
    data() {
        return {
            form: {
                name: '',
                password: '',
                password_confirmation: '',
                email: '',
            },
            userData: {},
            errors: new Errors(),
            showDiv: true,
            notiPassword: false,
        };
    },
    computed: {
        ...mapState(['user', 'impersonating']),
        userId() {
            return this.user.id;
        },
    },
    mounted() {
        this.infoUser();
        setTimeout(() => {
            this.addStyle('.navbar-menu');
            this.addStyle('.menu-list');
            this.addStyle('.bookmark-custom');
            this.addStyle('.menu-footer');
        }, 500);
    },
    methods: {
        fetch() {
            const formData = {
                name: this.form.name,
                email: this.form.email,
                password: this.form.password,
                password_confirmation: this.form.password_confirmation,
            };

            this.http.patch(this.route('users.update', { user: this.userId }), formData)
                .then(({ data }) => {
                    this.removeStyle('.navbar-menu');
                    this.removeStyle('.menu-list');
                    this.removeStyle('.bookmark-custom');
                    this.removeStyle('.menu-footer');
                    this.toastr.success(data.message);
                    this.$router.push(data.redirect);
                })
                .catch(e => {
                    this.errorHandler(e);
                    this.errors.set(e.response.data.errors);
                });
        },
        addStyle(selector) {
            const element = document.querySelector(selector);
            element.setAttribute('style', 'pointer-events: none !important; opacity: 0.5 !important;');
        },
        removeStyle(selector) {
            const element = document.querySelector(selector);
            element.removeAttribute('style');
        },
        infoUser() {
            this.http.get(this.route('administration.users.show', { user: this.userId }))
                .then(data => {
                    this.notiPassword = data.data.user.temporary_password;
                    if (data.data.user.socialite_external_auth === 'apple') {
                        this.form.email = data.data.user.email;
                        this.form.name = data.data.user.person.name;
                        this.showDiv = false;
                    }
                }).catch(e => console.error(e));
        },
    },
};
</script>
<style lang="scss" scoped>
.text-tam{
    font-size: 10px !important;
}

form {
    display: contents;
}

.broad-noti{
    width: 50% !important;
}

.broad{
    display: grid ;
    justify-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .broad-noti{
        width: 100% !important;
    }
}

.requirements{
    font-weight: bold;
    margin-bottom: 11px;
}

</style>
